//
// your custom scss goes here
//
// Bootstrap breakpoints
$breakpoint-sm: 767px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.col-custom-5 {
    // Extra Large Screens
    @media (min-width: $breakpoint-xl) {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  
    // Large Screens
    @media (max-width: ($breakpoint-lg - 1)) and (min-width: $breakpoint-md) {
      flex: 0 0 25% !important;
      max-width: 25% !important;
    }
  
    // Medium Screens
    @media (max-width: ($breakpoint-md - 1)) and (min-width: ($breakpoint-sm + 1)) {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  
    // Small Screens
    @media (max-width: $breakpoint-sm) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  .mt-custom {
    margin-top: 8rem; /* or whatever value you desire */
}
.col-custom-8 {
  /* Your existing styles for column layout here */

  // Extra Large Screens
  @media (min-width: $breakpoint-xl) {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
    font-size: 1.25rem; // Adjust font size for extra large screens
  }

  // Large Screens
  @media (max-width: ($breakpoint-lg - 1)) and (min-width: $breakpoint-md) {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
    font-size: 1rem; // Adjust font size for large screens
  }

  // Medium Screens
  @media (max-width: ($breakpoint-md - 1)) and (min-width: ($breakpoint-sm + 1)) {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
    font-size: 0.875rem; // Adjust font size for medium screens
  }

  // Small Screens
  @media (max-width: $breakpoint-sm) {
    flex: 0 0 12.5% !important;
    max-width: 12.5% !important;
    font-size: 0.6rem; // Adjust font size for small screens
  }
}


.col-custom-7 {
  // Extra Large Screens
  @media (min-width: $breakpoint-xl) {
    flex: 0 0 calc(100% / 7) !important; // 100% divided by 7 columns
    max-width: calc(100% / 7) !important;
    font-size: 1.25rem; 
  }

  // Large Screens
  @media (max-width: ($breakpoint-lg - 1)) and (min-width: $breakpoint-md) {
    flex: 0 0 calc(100% / 7) !important; // 100% divided by 7 columns
    max-width: calc(100% / 7) !important;
    font-size: 1rem; 
  }

  // Medium Screens
  @media (max-width: ($breakpoint-md - 1)) and (min-width: ($breakpoint-sm + 1)) {
    flex: 0 0 calc(100% / 7) !important;  
    max-width: calc(100% / 7) !important;
    font-size: 0.875rem;
  }

  // Small Screens
  @media (max-width: $breakpoint-sm) {
    flex: 0 0 calc(100% / 7) !important;  
    max-width: calc(100% / 7) !important;
    font-size: 0.6rem;
  }
}
// Custom 6 Columns
.col-custom-6 {
  // Extra Large Screens
  @media (min-width: $breakpoint-xl) {
    flex: 0 0 calc(100% / 6) !important;  
    max-width: calc(100% / 6) !important;
    font-size: 1.25rem; 
  }

  // Large Screens
  @media (max-width: ($breakpoint-lg - 1)) and (min-width: $breakpoint-md) {
    flex: 0 0 calc(100% / 6) !important;  
    max-width: calc(100% / 6) !important;
    font-size: 1rem; 
  }

  // Medium Screens
  @media (max-width: ($breakpoint-md - 1)) and (min-width: ($breakpoint-sm + 1)) {
    flex: 0 0 calc(100% / 6) !important;  
    max-width: calc(100% / 6) !important;
    font-size: 0.875rem;
  }

  // Small Screens
  @media (max-width: $breakpoint-sm) {
    flex: 0 0 calc(100% / 6) !important;  
    max-width: calc(100% / 6) !important;
    font-size: 0.6rem;
  }
}

// Custom 4 Columns
.col-custom-4 {
  // Extra Large Screens
  @media (min-width: $breakpoint-xl) {
    flex: 0 0 calc(100% / 4) !important;
    max-width: calc(100% / 4) !important;
    font-size: 1.25rem;
  }

  // Large Screens
  @media (max-width: ($breakpoint-lg - 1)) and (min-width: $breakpoint-md) {
    flex: 0 0 calc(100% / 4) !important;
    max-width: calc(100% / 4) !important;
    font-size: 1rem;
  }

  // Medium Screens
  @media (max-width: ($breakpoint-md - 1)) and (min-width: ($breakpoint-sm + 1)) {
    flex: 0 0 calc(100% / 4) !important;
    max-width: calc(100% / 4) !important;
    font-size: 0.875rem;
  }

  // Small Screens
  @media (max-width: $breakpoint-sm) {
    flex: 0 0 calc(100% / 4) !important;
    max-width: calc(100% / 4) !important;
    font-size: 0.6rem;
  }
}

// Custom 3 Columns
.col-custom-3 {
  // Extra Large Screens
  @media (min-width: $breakpoint-xl) {
    flex: 0 0 calc(100% / 3) !important;
    max-width: calc(100% / 3) !important;
    font-size: 1.25rem;
  }

  // Large Screens
  @media (max-width: ($breakpoint-lg - 1)) and (min-width: $breakpoint-md) {
    flex: 0 0 calc(100% / 3) !important;
    max-width: calc(100% / 3) !important;
    font-size: 1rem;
  }

  // Medium Screens
  @media (max-width: ($breakpoint-md - 1)) and (min-width: ($breakpoint-sm + 1)) {
    flex: 0 0 calc(100% / 3) !important;
    max-width: calc(100% / 3) !important;
    font-size: 0.875rem;
  }

  // Small Screens
  @media (max-width: $breakpoint-sm) {
    flex: 0 0 calc(100% / 3) !important;
    max-width: calc(100% / 3) !important;
    font-size: 0.6rem;
  }
}


.overlay-image {
  pointer-events: none; 
}

.product-add-to-cart {
  z-index: 2; 
  position: relative; 
}
/* By default, set margin-top to 0 for smaller screens */
.mt-lg-15 {
  margin-top: 0;
}
/* For large (lg) screens and up */
@media (min-width: 992px) {
  .mt-lg-15 {
    margin-top: 15rem;
  }
}
.mt-10{
  margin-top:10rem
}
.mt-15{
  margin-top:15rem
}
.mt-16{
  margin-top:16rem
}
.mt-17{
  margin-top:17rem
}
.mt-20{
  margin-top:20rem
}
.mt-21{
  margin-top:21rem
}
.mt-22{
  margin-top:22rem
}
.mt-24{
  margin-top:24rem
}
.pl-lg-35{
  padding-left:17.5rem !important
}
.pl-lg-40{
  padding-left:20rem !important
}
.pl-lg-58{
  padding-left:29rem !important
}
.pt-lg-8{
  padding-top:4rem !important
}
.pt-lg-16{
  padding-top:8rem !important
}
.pt-lg-24{
  padding-top:0rem !important
}
@media (min-width: 992px) {
  .pt-lg-24 {
    margin-top: 12rem;
  }
}
.pt-lg-26{
  padding-top:13rem !important
}
.pt-lg-30{
  padding-top:15rem !important
}
.pt-lg-35{
  padding-top:17.5rem !important
}
.pt-lg-45{
  padding-top:22.5rem !important
}
.pt-lg-66{
  padding-top:33rem !important
}
.ps-spec-center{
  padding-left:26rem !important
}
.ps-spec-center-more{
  padding-left:29rem !important
}
.ps-spec-center-less{
  padding-left:23rem !important
}
.ps-spec-3{
  padding-left:3rem !important
}
.ps-spec-5{
  padding-left:5rem !important
}
.ps-spec-6{
  padding-left:6rem !important
}
.ps-spec-7{
  padding-left:7rem !important
}
.ps-spec-10{
  padding-left:10rem !important
}
.ps-spec-12{
  padding-left:12rem !important
}
.ps-spec-15{
  padding-left:15rem !important
}
.ps-spec-17{
  padding-left:17rem !important
}
.ps-spec-18{
  padding-left:18rem !important
}
.ps-spec-20{
  padding-left:20rem !important
}
.ps-spec-22{
  padding-left:22rem !important
}



#alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; /* Ensures it's above other content */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center alerts horizontally */
}

/* By default, the table will take the full width */
.scrollable-table {
  overflow-x: auto;
  width: 100%;
}

/* When the screen size is below a certain breakpoint, say 768px for tablets, make the table scrollable */
@media (max-width: 768px) {
  .scrollable-table {
      max-width: 100%; /* or any other value that fits your design */
      overflow-x: scroll;
  }
}


