//
// misc
//

// scroll down
.scroll-down {
  transform: rotate(-45deg);
  position: absolute;
  bottom: rem(80);
  left: 50%;
  width: rem(16);
  height: rem(16);
  margin-left: -12px;
  border-left: rem(2) solid $white;
  border-bottom: rem(2) solid $white;
  -webkit-animation: scrollDown 1.4s infinite;
  animation: scrollDown 1.4s infinite;
  z-index: 8;
}

@keyframes scrollDown {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-100%, 100%);
    opacity: 0;
  }
}

.rotate {
  animation: rotate 16s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.grid {
  position: relative;

  > * {
    opacity: 0;
    position: absolute;

    &[class*="-shown"] {
      opacity: 1;
    }
  }
}

[data-isotope] {
  position: relative;
}

.logo {
  display: inline-block !important;
  height: auto;
  width: rem(120);
  max-width: 36%;

  &[class*="-sm"] {
    width: rem(80);
  }
}
